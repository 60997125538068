import { Link } from "gatsby"
import React from "react"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"

const SiteMap = () => (
  <Layout>
    <SEO title="Site Map" />
    <SectionWrapper>
      <h1>Site Map</h1>
      <ul>
        <li><Link to="/">Homepage</Link></li>
        <li><Link to="/tool">The Tool</Link></li>
        <li><Link to="/get-started">Get Started</Link></li>
        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
      </ul>
    </SectionWrapper>
  </Layout>
)

export default SiteMap

const SectionWrapper = styled.div`
  margin: auto;
  max-width: ${props => props.theme.section.width};
  padding: 50px 40px 30px;

  strong {
    margin: ${props => props.theme.spacing.large} 0 ${props => props.theme.spacing.small};
  }

  p {
    margin: ${props => props.theme.spacing.small} 0 ${props => props.theme.spacing.large};
  }
`